
import Vue from 'vue';
import { getDateStringFromFormat, getDateStringFromObject } from '@/utils/common.js';
import {
  CIRCUIT_CODE_ARR,
  BUNDLE_CODE_ARR,
  MANAGED_CODE_ARR,
  NORMAL_PREPAYMENT_OPTION,
  ADDITIONAL_GOODS_MODAL_IDS,
  ONE_MINUTE_PACKAGE_IDS,
  VPS_PACKAGE_IDS,
  ONE_MINUTE_ESSENTIAL_GOODSCODE_ARR,
  VPS_PACKAGE_ESSENTIAL_GOODSCODE_ARR,
} from '@/assets/data/nims/constants';
import { setCheckboxDisabledFnc } from '@/assets/data/nims/helper';
import BillLayout from '@/layouts/BillLayout.vue';
import formTitle from '@/assets/data/formTitle/index.json';
import FormLayout from '@/layouts/FormLayout.vue';
import TableLayout from '@/layouts/TableLayout.vue';
import ColumnLayout from '@/layouts/ColumnLayout.vue';
import Step01Modals from './fragments/Step01Modals.vue';
import { isEmptyObject, deepCopyObject } from '@/utils/common';
import { NimsAdditionalGoods } from '@/types/common/goods';
import { fitExpireDate } from '@gabia/gabia-order-helper';

const calculateMonthDayDifference = (start_date: string, end_date: string, period: number) => {
  const [, , sday] = start_date.split('-').map((num) => parseInt(num));
  const [, , eday] = end_date.split('-').map((num) => parseInt(num));

  //let edayCalculate = eday;
  let emonthCalculate = 0;

  // if (eday > 30) {
  //   //! 1달에 31 일은 인정하지 않음 (28 , 29, 30 만 인정 그이상은 30)
  //   edayCalculate = 30;
  // }

  // 1. 개설 희망일의 말일에서 숫자를 뺀다.
  // 2. 그게 30일이면 한 달로 쳐라!!!

  // 1. 개설 희망일의 말일에서 숫자를 뺀다.
  const givenDate = new Date(start_date);
  const nextMonth = new Date(givenDate.getFullYear(), givenDate.getMonth() + 1, 1);
  const lastDayOfMonth = new Date(nextMonth.getFullYear(), nextMonth.getMonth(), 0);

  let edayCalculate = lastDayOfMonth.getDate();

  if (15 >= sday) {
    emonthCalculate = -1;
  }

  if (edayCalculate - sday === 30) {
    return `${period}개월`;
  }

  return period + emonthCalculate === 0
    ? `${edayCalculate - sday}일`
    : `${period + emonthCalculate}개월 ${edayCalculate - sday}일`;
};

export default Vue.extend({
  name: 'NimsStep01Template',
  components: { BillLayout, FormLayout, TableLayout, ColumnLayout, Step01Modals },
  model: { prop: 'formState', event: 'change' },
  props: {
    formState: {
      type: Object,
      default() {
        return {
          period: '1',
          paymentType: '',
          chargeMethod: '', // D : 후납
          groupExpireDate: '',
          terms: {
            agree1: false,
            agree2: false,
            agree3: false,
          },
        };
      },
    },
  },
  data() {
    return {
      package_id: '',
      agreeAll: false,
      allowCircuitTypeArr: CIRCUIT_CODE_ARR,
      allowBackupTypeArr: BUNDLE_CODE_ARR,
      managedCodeArr: MANAGED_CODE_ARR,
      isCtTypeSelected: false,
      settlementInfo: {
        stfId: 0,
        buId: 0,
        os: 'L',
        startDate: '',
        additionalLinuxGoods: {} as NimsAdditionalGoods,
        additionalWindowGoods: {} as NimsAdditionalGoods,
      },
      additionalModalIds: ADDITIONAL_GOODS_MODAL_IDS,
      paymentOption: [{ value: '', label: '' }] as Array<{ value: string; label: string }>,
      formTitle,
    };
  },
  computed: {
    currentAddtionalGoods(): NimsAdditionalGoods {
      return this.settlementInfo.os === 'L'
        ? this.settlementInfo.additionalLinuxGoods
        : this.settlementInfo.additionalWindowGoods;
    },
    isLinuxAvaliable(): boolean {
      return !isEmptyObject(this.formState.additionalLinuxGoods);
    },
    isWindowAvaliable(): boolean {
      return !isEmptyObject(this.formState.additionalWindowGoods);
    },
    isOneMinuteSettingServer(): boolean {
      return ONE_MINUTE_PACKAGE_IDS.includes(parseInt(this.package_id));
    },
    isVpsPackages(): boolean {
      return VPS_PACKAGE_IDS.includes(parseInt(this.package_id));
    },
    agreeBoth(): boolean {
      return !!(this.formState.terms.agree1 && this.formState.terms.agree2 && this.formState.terms.agree3);
    },
    isGroupExist(): boolean {
      //! 엄밀하게 말하면 groupExpireDate 가 9999-12-31 일경우 그룹이 없는것처럼 나오나 그룹은 있는상태
      return (
        this.$route.query.group && this.formState.groupExpireDate && this.formState.groupExpireDate !== '9999-12-31'
      );
    },
    currentDate(): string {
      return getDateStringFromFormat('yyyy-mm-dd');
    },
    calculateDaysBetweenDatesHasNotGroup(): string {
      if (!this.settlementInfo.startDate) {
        return '0 일';
      }

      return calculateMonthDayDifference(
        this.settlementInfo.startDate,
        this.limitDate,
        parseInt(this.formState.period),
      );
    },

    calculateDaysBetweenDatesHasGroup(): string {
      if (!this.settlementInfo.startDate) {
        return '0 일';
      }

      return calculateMonthDayDifference(
        this.settlementInfo.startDate,
        this.formState.groupExpireDate,
        parseInt(this.formState.period),
      );
    },

    contractLimitDate(): string {
      if (!this.settlementInfo.startDate) {
        return '';
      }

      const limitYear = parseInt(this.settlementInfo.startDate.substring(0, 4)) + 2;
      const limitMonthDate = this.settlementInfo.startDate.substring(4);

      return `${limitYear}${limitMonthDate}`;
    },
    calenderLimitDate(): string {
      let date;
      const addMonths = (date, months) => {
        date.setMonth(date.getMonth() + months);
        return date;
      };

      if (this.isGroupExist) {
        date = new Date(this.formState.groupExpireDate);
        date.setDate(date.getDate() - 2);
      } else {
        date = addMonths(new Date(), 3);
      }

      return getDateStringFromObject({ format: 'yyyy-mm-dd', setDate: date });
    },
    limitDate(): string {
      /*
       *  1 ~ 15 일까지는 그달의 말일 ex) 2022-12-02 -> 2022-12-31
       *  16 ~ 31 일까지는 다음달의 말일 ex)  2022-12-22 -> 2023-01-31
       *
       */

      if (!this.settlementInfo.startDate) {
        return '';
      }

      this.$emit('limit-date', fitExpireDate(this.settlementInfo.startDate, this.formState.period));

      return fitExpireDate(this.settlementInfo.startDate, this.formState.period);
    },
    isStandAloneGoods(): boolean {
      return !this.package_id ? true : false;
    },
  },
  watch: {
    agreeBoth(newValue) {
      this.agreeAll = newValue;
    },
  },
  mounted() {
    this.package_id = this.$route.query.package as string;
    this.settlementInfo.startDate = this.formState.startDate;
    this.settlementInfo.stfId = this.formState.stfId;
    this.settlementInfo.buId = this.formState.buId;
    this.settlementInfo.additionalLinuxGoods = deepCopyObject(this.formState.additionalLinuxGoods);
    this.settlementInfo.additionalWindowGoods = deepCopyObject(this.formState.additionalWindowGoods);
    this.paymentOption = NORMAL_PREPAYMENT_OPTION;
  },
  methods: {
    onChangeAgreeAll(value) {
      if (value) {
        this.$emit('change', {
          ...this.formState,
          terms: {
            agree1: true,
            agree2: true,
            agree3: true,
          },
        });
      } else {
        this.$emit('change', {
          ...this.formState,
          terms: {
            agree1: false,
            agree2: false,
            agree3: false,
          },
        });
      }
    },
    onChangeTerms(e) {
      const { name, checked } = e;

      const params = {
        ...this.formState,
        terms: {
          ...this.formState.terms,
          [`agree${name}`]: checked,
        },
      };

      if (this.formState.chargeMethod !== 'D') {
        params.terms.agree3 = true;
      }

      this.$emit('change', params);
    },
    openModal(modal_name) {
      this.$GabiaModal.show(modal_name);
    },
    onChageFormState(property_name, value) {
      this.$emit('change', {
        ...this.formState,
        [property_name]: value,
      });
      this.$emit('calculate-price');
    },
    onChanegeOs(event) {
      this.settlementInfo.os = event.detail;
      let essentialCodeArr = [] as string[];

      for (const [, item] of Object.entries(this.settlementInfo.additionalLinuxGoods)) {
        item.checked = false;
      }

      for (const [, item] of Object.entries(this.settlementInfo.additionalWindowGoods)) {
        item.checked = false;
      }

      if (this.isOneMinuteSettingServer) {
        essentialCodeArr = ONE_MINUTE_ESSENTIAL_GOODSCODE_ARR;
      }

      if (this.isVpsPackages) {
        essentialCodeArr = VPS_PACKAGE_ESSENTIAL_GOODSCODE_ARR;
      }

      //! 1분 설치 서버 , VPS호스팅
      if (essentialCodeArr.length) {
        if (this.settlementInfo.os === 'L') {
          this.settlementInfo.additionalLinuxGoods = setCheckboxDisabledFnc(
            this.settlementInfo.additionalLinuxGoods,
            essentialCodeArr,
          );
        }

        if (this.settlementInfo.os === 'W') {
          this.settlementInfo.additionalWindowGoods = setCheckboxDisabledFnc(
            this.settlementInfo.additionalWindowGoods,
            essentialCodeArr,
          );
        }
        this.$emit('change-os', this.settlementInfo.os);
      }

      this.onChangeAddtionalGoods({});
    },
    onChangeAddtionalGoods(item) {
      this.isCtTypeSelected = item?.goodsGroup && item.goodsGroup.code === 'CT' && item.checked;

      //! 매니지드 상품이 선택 된 상황이면 통합 모니터링은 기본으로 깔렸다고 생각해야한다
      const additionalItems =
        this.settlementInfo.os === 'L'
          ? this.settlementInfo.additionalLinuxGoods
          : this.settlementInfo.additionalWindowGoods;

      for (const [, addtionItem] of Object.entries(additionalItems)) {
        if (addtionItem.goodsGroup.code === 'CT' && addtionItem.checked) {
          this.isCtTypeSelected = true;
        }
      }

      this.$emit('change', {
        ...this.formState,
        os: this.settlementInfo.os,
        additionalLinuxGoods: deepCopyObject(this.settlementInfo.additionalLinuxGoods),
        additionalWindowGoods: deepCopyObject(this.settlementInfo.additionalWindowGoods),
      });
      this.$emit('calculate-price');
    },
    onChangeStartDate(event) {
      const { value } = event.target;
      this.$emit('change', {
        ...this.formState,
        startDate: String(value),
      });
      this.$emit('calculate-price');
    },
    isAddtionalGoodsHasModal(modal_id: string) {
      return this.additionalModalIds.includes(modal_id);
    },
  },
});
