
import Vue from 'vue';
import { getGoodsInfo } from '@/api/goods';
import {
  getGoodsPriceExpiredSpecUseProxy,
  createNimsOrderProxy,
  deleteNimsOrderProxy,
  createNimGroupProxy,
  getNimsOrderInfoProxy,
  createNimGroupCompleteProxy,
} from '@/api/gabia-proxy';
import {
  createGroupForInfra,
  createGoodsInfoInfra,
  getGroupInfo,
  getHasOldIdc,
  getOneMinuteServerHasStock,
} from '@/api/infra-renew/index';
import { GoodsPriceInfo } from '@/api/goods/types';
import { getDateStringFromFormat } from '@/utils/common.js';
import {
  NIMS_PACKAGE,
  NIMS_STANDALONE_GOODS,
  CIRCUIT_CODE_ARR,
  BUNDLE_CODE_ARR,
  ONE_MINUTE_PACKAGE_IDS,
  VPS_PACKAGE_IDS,
  ONE_MINUTE_ESSENTIAL_GOODSCODE_ARR,
  VPS_PACKAGE_ESSENTIAL_GOODSCODE_ARR,
} from '@/assets/data/nims/constants';
import { GoodsAssembleFnc, GoodsAssembleAdditionalGoodsFnc, setCheckboxDisabledFnc } from '@/assets/data/nims/helper';
import CalculatorScreen from '@/components/common/CalculatorScreen/CalculatorScreen.vue';
import NimsStep01Template from '@/templates/nims/NimsStep01Template.vue';
import NimsStep01SkeletonTemplate from '@/templates/nims/NimsStep01SkeletonTemplate.vue';
import { GoodsPayType } from '@/assets/data/nims/constants';
import { moveToBillingPage } from '@/utils';
import { NimsAdditionalGoods } from '@/types/common/goods';
import { nimsGoodsTypeStructureGenerator } from '@/utils/common';
import { NimsGoodsHostingInfo } from '@/api/gabia-proxy/types';
import { orderMonthTilLastDayExpireFit } from '@gabia/gabia-order-helper';
const NIMS_APPLY_ERROR = 'NIMS_APPLY_ERROR';
const GOODS_ORDER_APPLY_ERROR = 'GOODS_ORDER_APPLY_ERROR';

export default Vue.extend({
  name: 'NimsStep01Brain',
  components: { CalculatorScreen, NimsStep01Template, NimsStep01SkeletonTemplate },
  props: {},
  data() {
    return {
      allowCircuitTypeArr: CIRCUIT_CODE_ARR,
      allowBackupTypeArr: BUNDLE_CODE_ARR,
      isRender: false,
      formState: {
        mainGoods: {},
        additionalLinuxGoods: {} as NimsAdditionalGoods,
        additionalWindowGoods: {} as NimsAdditionalGoods,
        terms: {
          agree1: false,
          agree2: false,
          agree3: false,
        },
        ctIds: [] as number[], // 매니지드가 선택될경우 통합 모니터링은 해제되어야한다(상품에 포함이 되어있음)
        emsIds: [] as number[], // 매니지드가 선택될경우 통합 모니터링은 해제되어야한다(상품에 포함이 되어있음)
        stfId: 0,
        buId: 0, // 묶음상품 패키지
        paymentType: 'PP' as GoodsPayType, // 추후 API가 오면 값을 새로 맞출것
        period: '1',
        startDate: '',
        os: 'L' as 'L' | 'W',
        chargeMethod: '', // D : 후납
        groupExpireDate: '',
      },
      accessToken: '',
      package_id: '',
      standalone_goods_id: '',
      order_number: '',
      order_seqno: 0,
      selectedGoodsPriceInfo: {
        totalPrice: 0 as number | undefined,
        totalPriceWithVat: 0 as number | undefined,
        vat: 0 as number | undefined,
        selectedGoods: [] as GoodsPriceInfo[],
        totalRulePrice: 0 as number | undefined,
        cutPrice: 0 as number | undefined,
      },
      essentialGoodsIds: [] as number[], // 계산 API 호출시 불러야하는 필수 값
      nimsHostingInfo: {} as NimsGoodsHostingInfo,
      createGroupInfo: {
        prosessFlag: true,
        seqNo: 0,
        serviceSeqno: 0,
        settlementObj: {
          settlement_id: '',
        },
      },
      groupSeqNo: '',
      limitDate: '',
      isOneMinuteServerHasStock: false, // 1분서버는 재고가 없다면 신청을 하면 안됨
    };
  },
  computed: {
    isOneMinuteSettingServer(): boolean {
      return ONE_MINUTE_PACKAGE_IDS.includes(parseInt(this.package_id));
    },
    isVpsPackages(): boolean {
      return VPS_PACKAGE_IDS.includes(parseInt(this.package_id));
    },
    userId(): string {
      return this.$store.state.userInfo?.user_id ?? '';
    },
  },
  watch: {},
  async mounted() {
    this.package_id = this.$route.query.package as string;
    this.standalone_goods_id = this.$route.query.goods_id as string;
    this.formState.startDate = getDateStringFromFormat('yyyy-mm-dd');
    this.formState.paymentType = (this.$route.query?.p_method as GoodsPayType) || 'PA';
    this.groupSeqNo = this.$route.query?.group ? String(this.$route.query?.group) : '';

    await this.getHasOldIdcFnc();

    if (this.groupSeqNo) {
      await this.getGroupInfoFnc();

      if (this.formState.groupExpireDate && this.formState.groupExpireDate !== '9999-12-31') {
        this.formState.period = String(
          orderMonthTilLastDayExpireFit(this.formState.startDate, this.formState.groupExpireDate),
        );
      }
    }

    if (NIMS_PACKAGE[this.package_id]) {
      await this.getPakageInfo();
      await this.getPriceInfoForExpireUseProxy();
      await this.getOneMinuteServerHasStockFnc(this.formState.os);
    } else if (NIMS_STANDALONE_GOODS[this.standalone_goods_id]) {
      await this.getManagedGoodsInfo();
      await this.getPriceInfoForExpireUseProxy();
    } else {
      alert('허용되지 않은 상품조회 입니다.');
    }
  },
  methods: {
    chageLimitDate(date_str: string) {
      this.limitDate = date_str;
    },
    async getOneMinuteServerHasStockFnc(serverOs: 'L' | 'W') {
      if (this.isOneMinuteSettingServer) {
        try {
          const { data } = await getOneMinuteServerHasStock(
            parseInt(this.formState.mainGoods[1][0].goods_id),
            serverOs,
          );

          this.isOneMinuteServerHasStock = data.data.stock === 'Y' ? true : false;
        } catch (error) {
          console.log(error);
        }
      }
    },
    async getHasOldIdcFnc() {
      const queryParams = this.$route.query;

      const package_id = queryParams?.package || false;
      const goods_id = queryParams?.goods_id || false;

      console.log(package_id, goods_id);

      try {
        const { data } = await getHasOldIdc(this.userId);

        if (data.has_old_idc_goods) {
          if (process.env.VUE_APP_MODE === 'development') {
            alert(
              '주의) 이 메세지는 dev 에서만 나옵니다. 기존 IDC 상품을 갖고 있는 유저라 리다이렉트 되는거고 기존 idc가 dev 환경이 따로 없어 prod로 리다이렉트 합니다. 디버깅 사항이 아닙니다. ',
            );
          }

          const redirect_url = package_id
            ? `https://idc.gabia.com/goods/application/server_step1?id=${package_id}`
            : 'https://idc.gabia.com/manage/resources/addition_service';

          window.location.href = redirect_url;
          return;
        }
      } catch (error) {
        console.log(error);
      }
    },

    async getGroupInfoFnc() {
      try {
        const { data } = await getGroupInfo(parseInt(this.groupSeqNo));
        this.formState.chargeMethod =
          data.data.service.pay_type.code === 'DA' || data.data.service.pay_type.code === 'DP' ? 'D' : 'P';
        this.formState.groupExpireDate = data.data.group_expire_date
          ? data.data.group_expire_date.substring(0, 10)
          : '';
        console.log(data);
      } catch (error) {
        console.warn(error);
      }
    },
    async goNextStep() {
      let isCreateGroup = false;
      if (!this.formState.terms.agree1 || !this.formState.terms.agree2 || !this.formState.terms.agree3) {
        alert('모든 약관에 동의해주세요.');
        window.scroll({
          top: document.body.scrollHeight,
          behavior: 'smooth',
        });
        return;
      }

      if (this.isOneMinuteSettingServer && !this.isOneMinuteServerHasStock) {
        alert('죄송합니다. 현재 신청하시는 상품의 재고가 없습니다. 고객센터(1544-4370)로 문의해주시기 바랍니다.');
        return;
      }
      this.$GlobalLoading.show();

      try {
        //! 그룹이 없는 경우
        if (!this.groupSeqNo) {
          await this.creatGroup();
          await this.getGroupOrderInfo();
          await this.createNimGroupComplete();
          await this.createNimGroupForInfra();
          isCreateGroup = true;
        }
        await this.createOrder();
        await this.createNimsBulkOrderFnc(isCreateGroup);
      } catch (error) {
        const errorObj = error as Error;
        const errorCode = errorObj.message;

        if (errorCode === NIMS_APPLY_ERROR) {
          alert('상품신청에서 오류가 발생했습니다.');
          await this.deleteOrder();
        } else if (errorCode === GOODS_ORDER_APPLY_ERROR) {
          alert('주문서 생성에서 오류가 발생했습니다.');
        }

        this.$GlobalLoading.hide();
      }
    },
    async creatGroup() {
      const params = {
        uri: '/v5/application/order',
        data: {
          order_page: 'application',
          checkout: {
            goods_list: [
              {
                id: 53001,
                quantity: 1,
                order_term: {
                  unit: 'own',
                  term: 1,
                },
                settlement_option: {
                  dep_item: 'IMS_N',
                },
              },
            ],
            checkout_option: {
              origin: 'www',
              order_number_option: {
                // 개발환경인 경우 입력
                suffix: process.env.VUE_APP_MODE === 'development' ? 'dev' : '',
              },
            },
            total_price: 0,
          },
          user_id: this.userId,
        },
      };

      try {
        const { data } = await createNimGroupProxy(params);
        this.createGroupInfo.seqNo = data.seqno;
        this.createGroupInfo.serviceSeqno = data.service_seqno;
        this.groupSeqNo = String(data.service_seqno);
      } catch (error) {
        this.createGroupInfo.prosessFlag = false;
        console.log(error);
      }

      console.log(params);
    },
    async getGroupOrderInfo() {
      if (!this.createGroupInfo.prosessFlag) {
        alert('error getGroupOrderInfo');
        return;
      }

      const params = {
        uri: `/v5/payment/${this.createGroupInfo.seqNo}`,
      };
      try {
        const { data } = await getNimsOrderInfoProxy(params);
        console.log(data);
        this.createGroupInfo.settlementObj.settlement_id = String(data.settlement_list[0].shistory_rowid);
        console.log(this.createGroupInfo);
      } catch (error) {
        this.createGroupInfo.prosessFlag = false;
        console.log(error);
      }
    },
    async createNimGroupComplete() {
      if (!this.createGroupInfo.prosessFlag) {
        alert('error createNimGroupComplete');
        return;
      }

      const params = {
        uri: '/v5/settlements/complete-application',
        data: {
          settlement_list: [this.createGroupInfo.settlementObj],
        },
      };

      try {
        const { data } = await createNimGroupCompleteProxy(params);
        console.log(data);
      } catch (error) {
        this.createGroupInfo.prosessFlag = false;
        console.log(error);
      }

      console.log(params);
    },
    async createNimGroupForInfra() {
      if (!this.createGroupInfo.prosessFlag) {
        alert('error createNimGroupForInfra');
        return;
      }

      try {
        const { data } = await createGroupForInfra(String(this.createGroupInfo.serviceSeqno));
        console.log(data);
      } catch (error) {
        console.log(error);
      }
    },
    async deleteOrder() {
      const params = {
        uri: '/mygabia/payment/order',
        data: {
          user_id: this.userId,
          ordernum_list: this.order_number,
        },
      };

      try {
        const response = await deleteNimsOrderProxy(params);
        console.log(response);
        alert('주문서 취소에 성공');
      } catch (error) {
        console.log(error);
        alert('주문서 취소에 실패했습니다.');
      }
    },
    async createOrder() {
      const params = {
        uri: '/v5/application-in-service/order',
        data: {
          user_id: this.userId,
          order_page: 'application',
          checkout: {
            service: {
              seqno: this.groupSeqNo,
            },
            goods_list:
              this.selectedGoodsPriceInfo?.selectedGoods?.map((goods) => {
                return {
                  id: goods.goods_id,
                  quantity: goods.quantity,
                  order_term: {
                    unit: goods.goods_term_unit,
                    term: goods.order_term_volume,
                  },
                  expire_fit_flag: 'Y',
                  expire_date:
                    this.formState.groupExpireDate && this.formState.groupExpireDate !== '9999-12-31'
                      ? this.formState.groupExpireDate
                      : this.limitDate,
                  start_date: this.formState.startDate,
                  settlement_option: {
                    dep_item: 'IMS_N',
                  },
                  main_goods_data: {
                    install_date: this.formState.startDate,
                  },
                };
              }) || [],
            checkout_option: {
              origin: 'gabia',
            },
            domain_data: { domain: this.groupSeqNo },
            total_price: this.selectedGoodsPriceInfo.totalPriceWithVat,
          },
        },
      };

      try {
        const { data } = await createNimsOrderProxy(params);

        this.order_number = data.order_number;
        this.order_seqno = data.seqno;
        this.nimsHostingInfo = data.service_info.length
          ? data.service_info[0]
          : { main_goods_pk_list: [], service_seqno: 0 };
      } catch (error) {
        console.log(error);
        throw new Error(GOODS_ORDER_APPLY_ERROR);
      }
    },
    async createNimsBulkOrderFnc(isCreateGroup: boolean) {
      try {
        const osChecker = this.standalone_goods_id ? 'N' : (this.formState.os as 'L' | 'W');

        const response = await createGoodsInfoInfra(parseInt(this.groupSeqNo), this.order_seqno, osChecker);
        console.log(response);

        if (process.env.VUE_APP_MODE === 'development') {
          alert(
            `결제 페이지로 이동합니다.  3단계 hst_no=${this.order_seqno}  * 해당메세지는 develop 에서만 나옵니다. - 개발디버깅 용`,
          );
        }

        if (
          this.formState.paymentType === GoodsPayType.PRE_PAID_NOMAL ||
          this.formState.paymentType === GoodsPayType.PRE_PAID_REGULAR
        ) {
          //! 선납조건은 2단계로
          const idLink = this.standalone_goods_id
            ? `goods_id=${this.standalone_goods_id}`
            : `package=${this.package_id}`;

          let params = {
            ordernum: this.order_number,
            return_url: `${process.env.VUE_APP_APPLICATION_URL}/apply-step-03/nims?hst_no=${this.order_seqno}`,
            back_url: `${process.env.VUE_APP_APPLICATION_URL}/apply-step-01/nims?${idLink}&group=${this.groupSeqNo}`,
          };

          if (isCreateGroup) {
            params['nobatch_payment'] = 'Y';
          }

          moveToBillingPage(params);
        } else {
          //! 후납조건은 3단계로
          this.$router.push({
            name: 'apply-03',
            params: { service_type: 'nims' },
            query: { hst_no: String(this.order_seqno) },
          });
        }
      } catch (error) {
        console.log(error);
        throw new Error(NIMS_APPLY_ERROR);
      }
    },
    async getPakageInfo() {
      const params = {
        'options[only_sales_goods]': 'N',
        'package_list[0][id]': this.package_id,
      };

      if (NIMS_PACKAGE[this.package_id].linux) {
        params['package_list[1][id]'] = NIMS_PACKAGE[this.package_id].linux;
      }

      if (NIMS_PACKAGE[this.package_id].window) {
        params['package_list[2][id]'] = NIMS_PACKAGE[this.package_id].window;
      }

      try {
        const goodsInfo = await getGoodsInfo(params);

        if (goodsInfo?.package_list) {
          //! api 가 첫 호출시 캐싱을 진행하여 요청 순번대로 오지 않는경우가 발생했으나 해당 경우는 백엔드에서 처리가 어렵다는 이야기를 전달받아 프론트에서 재처리
          const tmp_main_goods = goodsInfo.package_list.filter((item) => String(item.id) === this.package_id);
          const tmp_linux_goods = goodsInfo.package_list.filter(
            (item) => item.id === NIMS_PACKAGE[this.package_id].linux,
          );
          const tmp_window_goods = goodsInfo.package_list.filter(
            (item) => item.id === NIMS_PACKAGE[this.package_id].window,
          );

          this.formState.mainGoods = tmp_main_goods.length
            ? GoodsAssembleFnc(tmp_main_goods[0].package_goods_list)
            : {};
          this.formState.additionalLinuxGoods = tmp_linux_goods.length
            ? GoodsAssembleAdditionalGoodsFnc(tmp_linux_goods[0].package_goods_list)
            : {};
          this.formState.additionalWindowGoods = tmp_window_goods.length
            ? GoodsAssembleAdditionalGoodsFnc(tmp_window_goods[0].package_goods_list)
            : {};

          // 선택이 아닌 필수 goods_id는 미리 세팅을 해둔다

          for (const [, item] of Object.entries(this.formState.mainGoods)) {
            if (
              Array.isArray(item) &&
              item.length === 1 &&
              !this.allowCircuitTypeArr.includes(item[0].goods.goods_type.code) &&
              !this.allowBackupTypeArr.includes(item[0].goods.goods_type.code)
            ) {
              this.essentialGoodsIds.push(item[0].goods_id);
            }

            if (Array.isArray(item) && this.allowCircuitTypeArr.includes(item[0].goods.goods_type.code)) {
              this.formState.stfId = item[0].goods_id;
            }

            if (Array.isArray(item) && this.allowBackupTypeArr.includes(item[0].goods.goods_type.code)) {
              this.formState.buId = item[0].goods_id;
            }
          }

          /**
           *  제약 사항 및 예외 조건
           * https://confluence.gabia.com/pages/viewpage.action?pageId=74087329
           */

          for (const [, item] of Object.entries(this.formState.additionalLinuxGoods)) {
            //! 매니지드선택시 통합모니터링이
            //! windows 아이디도 동일하여 리눅스만 실행
            if (item.goodsGroup.code === 'CT') {
              this.formState.ctIds.push(parseInt(item.selectedId));
            }

            if (item.goodsGroup.code === 'EMS') {
              item.list.forEach((good) => {
                this.formState.emsIds.push(parseInt(good.goods_id));
              });
            }
          }

          //! 1분 설치 서버
          if (this.isOneMinuteSettingServer) {
            this.formState.additionalLinuxGoods = setCheckboxDisabledFnc(
              this.formState.additionalLinuxGoods,
              ONE_MINUTE_ESSENTIAL_GOODSCODE_ARR,
            );
          }

          //! VPS
          if (this.isVpsPackages) {
            this.formState.additionalLinuxGoods = setCheckboxDisabledFnc(
              this.formState.additionalLinuxGoods,
              VPS_PACKAGE_ESSENTIAL_GOODSCODE_ARR,
            );
          }

          this.formState.mainGoods[1][0].goods.resource_list = this.formState.mainGoods[1][0].goods.resource_list.sort(
            (a, b) => {
              return a.sort_order - b.sort_order;
            },
          );

          this.isRender = true;
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getManagedGoodsInfo() {
      const params = {
        'options[only_sales_goods]': 'N',
        'goods_list[0][id]': this.standalone_goods_id,
      };

      const goodsInfo = await getGoodsInfo(params);

      if (goodsInfo?.goods_list) {
        this.formState.mainGoods = {
          1: [
            {
              goods: goodsInfo.goods_list[0],
              goods_group: null,
              goods_id: goodsInfo.goods_list[0].id,
            },
          ],
        };
        this.essentialGoodsIds.push(goodsInfo.goods_list[0].id);
        this.isRender = true;
      }
    },
    async getPriceInfoForExpireUseProxy() {
      this.$GlobalLoading.show();
      const isManagedGoodsSelected = (goodsIds, mangesIds) => {
        const returnArr = goodsIds.filter((good) => mangesIds.includes(good));
        return !!returnArr.length;
      };

      let goodsIdArr = [...this.essentialGoodsIds];
      let orderTerm = parseInt(this.formState.period);

      const additionalGoodsObj =
        this.formState.os === 'L' ? this.formState.additionalLinuxGoods : this.formState.additionalWindowGoods;

      //! 회선비
      if (this.formState.stfId) {
        goodsIdArr.push(this.formState.stfId);
      }

      //! 백업
      if (this.formState.buId) {
        goodsIdArr.push(this.formState.buId);
      }

      //! 함께 신청하면 좋은 상품
      for (const [, item] of Object.entries(additionalGoodsObj)) {
        if (item.checked) {
          goodsIdArr.push(parseInt(item.selectedId));
        }
      }

      if (isManagedGoodsSelected(goodsIdArr, this.formState.ctIds)) {
        goodsIdArr = goodsIdArr.filter((id) => !this.formState.emsIds.includes(id));
      }

      const goodsPriceParams = {
        uri: '/v4/goods/pricing/calculate',
      };

      if (this.formState.groupExpireDate && this.formState.groupExpireDate !== '9999-12-31') {
        //! 그룹이 존재하는경우 그룹 만기일을 기준으로 계산한다
        //! 말일이 아닌경우 ex) 7월 25일 x  7월 31일 o  무조건 -3 이 나옴
        //! 추가조건 포함 : 만기일이 '9999-12-31' 일경우 일단 만기일이 없는셈 친다
        orderTerm = orderMonthTilLastDayExpireFit(this.formState.startDate, this.formState.groupExpireDate);
      }

      goodsIdArr.forEach((goodsId, idx) => {
        goodsPriceParams[`data[goodsList[${idx}][quantity]]`] = 1;
        goodsPriceParams[`data[goodsList[${idx}][id]]`] = goodsId;
        goodsPriceParams[`data[goodsList[${idx}][order_term]]`] = orderTerm;
        goodsPriceParams[`data[goodsList[${idx}][expire_fit_flag]]`] = 'Y';
        goodsPriceParams[`data[goodsList[${idx}][custom_key]]`] = idx;

        goodsPriceParams[`data[goodsList[${idx}][start_date]]`] = this.formState.startDate;

        // if (!orderTerm) {
        //   goodsPriceParams[`data[goodsList[${idx}][start_date]]`] = this.formState.startDate;
        // }
      });

      goodsPriceParams[`data[order_situation]`] = 'application';

      const { data } = await getGoodsPriceExpiredSpecUseProxy(goodsPriceParams);

      console.log(data);

      this.selectedGoodsPriceInfo = nimsGoodsTypeStructureGenerator(data, {
        carve_code: 'NIMS',
        carve_code_str: 'NIMS',
      });

      this.selectedGoodsPriceInfo = {
        totalPrice: data?.total_pricing?.total_price || 0,
        totalPriceWithVat: data?.total_pricing.total_price_with_vat || 0,
        vat: data?.total_pricing.vat || 0,
        selectedGoods:
          data?.goods_pricing_list.sort((a, b) => {
            return Number(a.custom_key) - Number(b.custom_key);
          }) || [],
        totalRulePrice: data?.total_pricing.total_rule_price || 0,
        cutPrice: data?.total_pricing.cut_price || 0,
      };

      this.$GlobalLoading.hide();
    },
  },
});
